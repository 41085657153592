import {
	Button,
	Input,
	Modal,
	SearchCategoryInput,
	Tooltip,
	useToast,
} from "@withjuly/solisv2";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUpdateMediaKit } from "~/utils/api/query/mediakit";
import {
	ContactSourceSchema,
	CreatorProfile,
	MediaKit,
	MediaKitHighlightedPost,
	PostPickerError,
	UpdateMediaKitSchema,
} from "@withjuly/fabric";
import { FormProvider } from "react-hook-form";
import { trpc } from "~/components/Utility/trpc";
import { useZodForm } from "~/utils/hooks/zod-form";
import { useCreator } from "~/utils/context/creator";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import { ZodInput } from "~/components/Input/ZodInput";
import { Label, IconButton, ToggleSwitch, ScrollArea } from "@withjuly/solisv2";
import {
	ArrowLeft,
	DotsSixVertical,
	Info,
	Link,
	MagnifyingGlass,
	PencilSimple,
	Plus,
	Trash,
	X,
} from "@withjuly/julycons/bold";
import {
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Loader } from "@withjuly/solis";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Switch } from "~/components/Input/SwitchV2";
import { CONTENT_OPTIONS } from "~/components/Agency/ContentCategoryInput";
import { useAgency } from "~/utils/context/agency";
import { DISABLE_DELETE_CONTENT_TAG_MODAL } from "~/utils/api/client";

interface HeaderEditorProps {
	onClickBack: (editor: ActiveEditorType) => void;
}

type DeleteContentTagModal =
	| { open: false; tag: undefined }
	| { open: true; tag: string };

export const HeaderEditor = ({ onClickBack }: HeaderEditorProps) => {
	const isHighlightedPostsEnabled = useFeatureFlagEnabled(
		"mediakit-highlighted-posts",
	);

	const [isPostPickerOpen, setIsPostPickerOpen] = useState<boolean>(false);
	const [searchCategory, setSearchCategory] = useState<string>("");
	const [isDeleteContentModalOpen, setIsDeleteContentModalOpen] =
		useState<DeleteContentTagModal>({ open: false, tag: undefined });

	const { toast } = useToast();
	const { creatorProfile } = useCreator();
	const { agencyProfile } = useAgency();

	const utils = trpc.useContext();
	const updateMediaKit = useUpdateMediaKit(500, () => {
		utils.mediaKit.get.invalidate();
	});
	const { data: mediaKit } = trpc.mediaKit.get.useQuery();
	const updateAgencyContentCategories =
		trpc.agency.updateCustomContentCategories.useMutation();
	const updateMediaKitTags = trpc.mediaKit.updateMediaKitTags.useMutation();

	const saveCreatorTags = (newContent: string[]) => {
		updateMediaKitTags.mutate(newContent, {
			onSuccess: () => {
				utils.mediaKit.get.invalidate();
			},
		});
	};

	const categories = useMemo(() => {
		if (mediaKit) {
			return mediaKit.tags === "" || mediaKit.tags === undefined
				? []
				: mediaKit?.tags.split(",");
		}
		return [];
	}, [mediaKit]);

	const options = useMemo(() => {
		if (agencyProfile && agencyProfile.contentCategories) {
			return [...agencyProfile.contentCategories, ...CONTENT_OPTIONS].sort();
		}
		return CONTENT_OPTIONS.sort();
	}, [agencyProfile]);

	const form = useZodForm({
		schema: UpdateMediaKitSchema,
		values: {
			name: mediaKit?.name,
			bio: mediaKit?.bio,
			tags: mediaKit?.tags,
			contact:
				mediaKit !== undefined &&
				mediaKit.contactSource === ContactSourceSchema.Enum.custom
					? mediaKit.contact
					: "",
			darkMode: mediaKit?.darkMode,
			pronouns: mediaKit?.pronouns ?? null,
			showHighlightedPosts: mediaKit?.showHighlightedPosts,
		},
		submit: async (data) => {
			updateMediaKit.mutate({
				...data,
			});
		},
	});

	const { onSubmit, watch } = form;

	useEffect(() => {
		const { unsubscribe } = watch(() => onSubmit());
		return () => unsubscribe();
	}, [watch, onSubmit]);

	return (
		<div className="flex w-full flex-col rounded-lg">
			<div className="flex w-full items-center gap-4 px-8 py-7">
				<IconButton
					icon={ArrowLeft}
					size="sm"
					variant="secondary"
					onClick={() => onClickBack("none")}
				/>
				<p className="text-header-xl font-repro">Header Section</p>
			</div>
			<div className="bg-stroke-secondary h-px w-full" />
			<ScrollArea className="max-h-[calc(100vh_-_184px)]">
				<div className="flex flex-col p-8">
					<FormProvider {...form}>
						<form className="flex flex-col gap-8">
							<ZodInput
								name="contact"
								label="Contact Email"
								description="Brands inquiring about talent will be directed here."
								placeholder={
									mediaKit !== undefined &&
									mediaKit.contactSource !== ContactSourceSchema.Enum.custom
										? mediaKit.contact
										: undefined
								}
								// Only render icon within agency portal
								trailingIcon={
									agencyProfile !== undefined
										? () => (
												<Tooltip
													icon={Info}
													tooltip={`If a custom contact email is not provided we will use your manager or agency email. 
													${mediaKit !== undefined && mediaKit.contactSource !== ContactSourceSchema.Enum.custom ? `Currently using your ${mediaKit.contactSource} email.` : ""}`}
												/>
											)
										: undefined
								}
							/>
							<div className="flex flex-col gap-6">
								<div className="flex flex-col gap-3">
									<SearchCategoryInput
										value={searchCategory}
										onChange={(e) => setSearchCategory(e.target.value)}
										placeholder="Search categories (Fashion, Fitness, etc.)"
										options={options.sort()}
										onOptionSelected={(value) => {
											const newContent = [...categories, value];
											saveCreatorTags(newContent);
											setSearchCategory("");
										}}
										leadingIcon={MagnifyingGlass}
										customOptions={
											agencyProfile
												? agencyProfile.contentCategories
												: undefined
										}
										selectedOptions={categories}
										onCreateClicked={(value) => {
											const newContent = [...categories, value];
											saveCreatorTags(newContent);
											if (agencyProfile) {
												updateAgencyContentCategories.mutate(
													{
														newCategories: [
															...agencyProfile.contentCategories,
															value,
														],
													},
													{
														onSuccess: () => {
															toast({
																title: "Content Category created",
																description:
																	"Custom content categories can be managed from Agency Settings.",
																variant: "success",
															});
															setSearchCategory("");
															utils.agency.get.invalidate();
														},
													},
												);
											}
										}}
									/>
									<div className="flex flex-wrap gap-1">
										{categories.filter((category) => category.length > 0)
											.length === 0 ? (
											<div className="flex h-[28px] items-center">
												<p className="font-repro text-paragraph-md text-text-placeholder">
													No categories added
												</p>
											</div>
										) : (
											categories
												.filter((category) => category.length > 0)
												.map((tag, i) => {
													return (
														<div
															key={`${tag}-${i}`}
															className="my-1 flex h-5 items-center"
														>
															<div className="bg-sky-alpha-2 text-paragraph-sm text-brand font-repro flex h-[28px] items-center justify-center gap-1 rounded-full px-[10px]">
																{tag}
																<button
																	onClick={(e) => {
																		e.preventDefault();
																		const disableConfirmationModal =
																			localStorage.getItem(
																				DISABLE_DELETE_CONTENT_TAG_MODAL,
																			);
																		if (disableConfirmationModal) {
																			saveCreatorTags(
																				[...categories].filter(
																					(t) => t !== tag,
																				),
																			);
																		} else {
																			setIsDeleteContentModalOpen({
																				open: true,
																				tag: tag,
																			});
																		}
																	}}
																>
																	<X className="h-[14px] w-[14px]" />
																</button>
															</div>
														</div>
													);
												})
										)}
									</div>
								</div>
							</div>
							{isHighlightedPostsEnabled ? (
								<>
									<div className="bg-stroke-tertiary h-px w-full" />
									<div className="flex w-full flex-col gap-6">
										<div>
											<p className="text-button-md font-repro">
												Customize Highlighted content
											</p>
											<p className="text-paragraph-sm text-text-secondary font-repro">
												Add links to specific posts to show them in the Media
												Kit header section.
											</p>
										</div>
										{mediaKit?.highlightedPosts &&
										mediaKit.highlightedPosts.length > 0 ? (
											<div className="flex w-full gap-2 overflow-x-scroll rounded-lg">
												{mediaKit.highlightedPosts.map((post, index) => {
													return (
														<button
															key={index}
															onClick={(e) => {
																e.preventDefault();
																setIsPostPickerOpen(() => true);
															}}
															className="relative aspect-square h-32 w-32 rounded-lg object-contain object-center"
														>
															{post.mediaType === "Instagram Reel" ? (
																<video
																	src={post.thumbnailUrl}
																	className="h-full w-full rounded-lg object-cover transition-all group-hover:scale-105"
																	autoPlay
																	muted
																	loop
																	controls={false}
																/>
															) : (
																<img
																	className="aspect-square h-32 w-32 rounded-lg object-cover object-center"
																	src={post.thumbnailUrl}
																	alt={`Thumbnail for the post at ${post.postUrl}`}
																/>
															)}
														</button>
													);
												})}
											</div>
										) : null}
										<Button
											leadingIcon={
												mediaKit?.highlightedPosts &&
												mediaKit?.highlightedPosts.length > 0
													? PencilSimple
													: Plus
											}
											variant="secondary"
											onClick={(e) => {
												e.preventDefault();
												setIsPostPickerOpen(() => true);
											}}
											className="min-h-10"
										>
											{`${mediaKit?.highlightedPosts && mediaKit?.highlightedPosts.length > 0 ? "Edit" : "Add"} highlighted content`}
										</Button>
									</div>
								</>
							) : null}
							<div className="bg-stroke-tertiary h-px w-full" />
							<div className="flex flex-col gap-4">
								<HeaderStatToggle
									mediaKit={mediaKit}
									updateMediaKit={updateMediaKit}
									label="Show Total Audience"
									name="total"
								/>
								<HeaderStatToggle
									mediaKit={mediaKit}
									updateMediaKit={updateMediaKit}
									label="Show Location"
									name="location"
								/>
								<HeaderStatToggle
									mediaKit={mediaKit}
									updateMediaKit={updateMediaKit}
									label="Show Primary Platform"
									name="platform"
								/>
								<Switch
									className="gap-2"
									label="Show Highlighted Content"
									name="showHighlightedPosts"
								/>
							</div>
						</form>
					</FormProvider>
				</div>
			</ScrollArea>
			{creatorProfile && mediaKit && isHighlightedPostsEnabled ? (
				<HighlightPostPicker
					isOpen={isPostPickerOpen}
					setIsOpen={setIsPostPickerOpen}
					mediaKit={mediaKit}
					creatorProfile={creatorProfile}
				/>
			) : null}
			{isDeleteContentModalOpen.open ? (
				<DeleteContentModal
					isOpen={isDeleteContentModalOpen.open}
					setIsOpen={() =>
						setIsDeleteContentModalOpen({ open: false, tag: undefined })
					}
					tag={isDeleteContentModalOpen.tag}
					remove={() => {
						saveCreatorTags(
							[...categories].filter((t) => t !== isDeleteContentModalOpen.tag),
						);
					}}
				/>
			) : null}
		</div>
	);
};

interface HeaderStatToggleProps {
	mediaKit?: MediaKit;
	updateMediaKit: ReturnType<typeof useUpdateMediaKit>;
	label: string;
	name: string;
}

const HeaderStatToggle: React.FC<HeaderStatToggleProps> = ({
	mediaKit,
	updateMediaKit,
	label,
	name,
}) => {
	return (
		<div className="flex items-center gap-2">
			<ToggleSwitch
				toggle={mediaKit?.headerStats.includes(name) ?? false}
				onToggle={(toggle) => {
					if (!mediaKit) {
						return;
					}

					const hasStat = mediaKit?.headerStats.includes(name) ?? false;
					if (toggle && !hasStat) {
						updateMediaKit.mutate({
							...mediaKit,
							headerStats: `${mediaKit.headerStats},${name}`,
						});
					} else if (!toggle && hasStat) {
						updateMediaKit.mutate({
							...mediaKit,
							headerStats: mediaKit.headerStats.replaceAll(name, ""),
						});
					}
				}}
			/>
			<Label className="text-paragraph-sm flex items-center gap-3">
				{label}
			</Label>
		</div>
	);
};

interface HighlightPostPickerProps {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	mediaKit: MediaKit;
	creatorProfile: CreatorProfile;
}

export const HighlightPostPicker: React.FC<HighlightPostPickerProps> = ({
	isOpen,
	setIsOpen,
	mediaKit,
	creatorProfile,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputValue, setInputValue] = useState<string>("");
	const addPostToHighlightedContent =
		trpc.mediaKit.addPostToHighlightedContent.useMutation();
	const updateHighlightedContent =
		trpc.mediaKit.updateHighlightedContent.useMutation();
	const utils = trpc.useContext();
	const [posts, setPosts] = useState<MediaKitHighlightedPost[]>([]);
	const { toast } = useToast();

	const isInputValid = useMemo(() => {
		const isValidRegex =
			/(v=(\w+))|(instagram.com\/((p)|(reel))\/.*)|(tiktok.com\/@.+\/video\/\d+)/;
		return isValidRegex.test(inputValue);
	}, [inputValue]);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 8,
			},
		}),
		useSensor(KeyboardSensor),
	);

	const onAdd = useCallback(() => {
		if (isInputValid) {
			addPostToHighlightedContent.mutate(
				{
					creatorProfileUuid: creatorProfile.uuid,
					postUrl: inputValue,
					mediaKitUuid: mediaKit.uuid,
				},
				{
					onSuccess: () => {
						utils.mediaKit.get.invalidate();
						setInputValue(() => "");
					},
					onError: (e) => {
						const parsedError = PostPickerError.PostPickerErrorSchema.safeParse(
							e.data,
						);

						if (parsedError.success) {
							const error = parsedError.data;
							toast({
								title: error.message,
								description: error.description,
							});
						} else {
							toast({
								title: "Error Uploading Post",
								description:
									"There was an error uploading the post. Please check the URL and try again.",
							});
						}
					},
				},
			);
		}
	}, [
		addPostToHighlightedContent,
		creatorProfile.uuid,
		inputValue,
		isInputValid,
		mediaKit.uuid,
		toast,
		utils.mediaKit.get,
	]);

	const onDelete = useCallback(
		(postUrl: string) => {
			const postIndex = posts.findIndex((post) => post.postUrl === postUrl);

			if (postIndex !== -1) {
				const newPosts = posts.slice();
				newPosts.splice(postIndex, 1);
				setPosts(newPosts);

				updateHighlightedContent.mutate(
					{
						creatorProfileUuid: creatorProfile.uuid,
						posts: newPosts,
						mediaKitUuid: mediaKit.uuid,
					},
					{
						onSuccess: () => {
							utils.mediaKit.get.invalidate();
						},
					},
				);
			}
		},
		[
			posts,
			updateHighlightedContent,
			creatorProfile.uuid,
			mediaKit.uuid,
			utils.mediaKit.get,
		],
	);

	const onDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;
		const activeIndex = posts.findIndex((f) => f.postUrl === active.id);
		const overIndex = posts.findIndex((f) => f.postUrl === over?.id);
		if (activeIndex !== -1 && overIndex !== -1) {
			const newPosts = posts.slice();
			const [activeItem] = newPosts.splice(activeIndex, 1);
			if (activeItem) {
				const newOverIndex = posts.findIndex((f) => f.postUrl === over?.id);
				newPosts.splice(newOverIndex, 0, activeItem);
				setPosts(() => newPosts);
				updateHighlightedContent.mutate(
					{
						creatorProfileUuid: creatorProfile.uuid,
						posts: newPosts,
						mediaKitUuid: mediaKit.uuid,
					},
					{
						onSuccess: () => {
							utils.mediaKit.get.invalidate();
						},
					},
				);
			}
		}
	};

	useEffect(() => {
		if (mediaKit?.highlightedPosts.length === 0) {
			setPosts(() => []);
		} else {
			setPosts(() => mediaKit?.highlightedPosts);
		}
	}, [mediaKit]);

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen} size="lg">
			<Modal.Header
				title="Add highlighted content"
				description={`Show up to 3 posts at the top of ${creatorProfile.user.firstName}'s Media Kit.`}
			/>
			<Modal.Body>
				<div className="flex w-[576px] flex-col gap-8 py-2">
					<div className="flex w-[576px] flex-col gap-1">
						<div className="flex w-full items-center gap-4">
							<Input
								ref={inputRef}
								label="Link to post"
								leadingIcon={Link}
								description="Paste links to posts on Instagram, TikTok, or YouTube."
								placeholder="https://"
								onChange={(e) => {
									setInputValue(() => e.target.value);
								}}
								value={inputValue}
							/>

							<Button
								disabled={!isInputValid || posts.length === 3}
								onClick={onAdd}
							>
								{addPostToHighlightedContent.isLoading ? "Adding..." : "Add"}
							</Button>
						</div>
						<p className="text-body-sm text-danger-400 h-0">
							{!isInputValid && inputValue !== ""
								? "Please enter a valid Instagram, TikTok, or YouTube post"
								: null}
						</p>
					</div>
					<ScrollArea className="w-full overflow-x-scroll">
						<div className="flex items-center gap-4">
							<DndContext
								onDragEnd={onDragEnd}
								sensors={sensors}
								modifiers={[restrictToHorizontalAxis]}
							>
								<SortableContext items={posts.map((post) => post.postUrl)}>
									{posts.map((post, index) => {
										return (
											<SortablePost
												key={`${index}-${post.postUrl}`}
												onDelete={onDelete}
												post={post}
											/>
										);
									})}
								</SortableContext>
							</DndContext>
							{posts.length !== 3 ? (
								<div className="flex flex-col gap-2 self-start">
									<button
										className="bg-surface-secondary hover:bg-surface-hover-1 flex h-[160px] w-[160px] items-center justify-center rounded-lg "
										onClick={() => {
											if (inputRef.current) {
												inputRef.current.focus();
											}
										}}
										disabled={addPostToHighlightedContent.isLoading}
									>
										{addPostToHighlightedContent.isLoading ? (
											<Loader />
										) : (
											<Plus />
										)}
									</button>
								</div>
							) : null}
						</div>
					</ScrollArea>
				</div>
			</Modal.Body>
		</Modal.Root>
	);
};

interface SortablePostProps {
	post: MediaKitHighlightedPost;
	onDelete: (postUrl: string) => void;
}

export const SortablePost: React.FC<SortablePostProps> = ({
	post,
	onDelete,
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: post.postUrl });

	return (
		<div
			key={post.postUrl}
			className="group/item flex cursor-pointer flex-col items-center justify-center gap-2 rounded-lg"
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={{
				transform: CSS.Translate.toString(transform),
				transition,
				opacity: isDragging ? 0.75 : 1,
			}}
		>
			<a
				href={post.postUrl}
				target="_blank"
				className="relative aspect-square h-[160px] w-[160px] rounded-lg object-contain object-center"
			>
				<div
					className="absolute right-0 top-0 z-20 hidden cursor-pointer rounded-lg p-2 group-hover/item:block"
					role="button"
					aria-label="Delete post from highlighted posts"
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						onDelete(post.postUrl);
					}}
				>
					<Trash />
				</div>
				{post.mediaType === "Instagram Reel" ? (
					<video
						src={post.thumbnailUrl}
						className="h-full w-full rounded-lg object-cover transition-all group-hover:scale-105"
						autoPlay
						muted
						loop
						controls={false}
					/>
				) : (
					<img
						className="z-10 aspect-square h-[160px] w-[160px] rounded-lg object-cover object-center transition-all group-hover/item:opacity-40"
						src={post.thumbnailUrl}
						alt={`Thumbnail for the post at ${post.postUrl}`}
					/>
				)}
			</a>
			<DotsSixVertical className="rotate-90" />
		</div>
	);
};

interface DeleteContentModalProps {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	tag: string;
	remove: () => void;
}

const DeleteContentModal: React.FC<DeleteContentModalProps> = ({
	isOpen,
	setIsOpen,
	tag,
	remove,
}) => {
	const [checked, setChecked] = useState<boolean>(false);
	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header
				title={`Remove ${tag
					.split(" ")
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(" ")}?`}
			/>
			<Modal.Body>
				<p className="text-paragraph-sm font-repro text-text-secondary">
					<span>{tag}</span> will be removed from creator&apos;s Content Types
					everywhere (Roster, Decks, etc).
				</p>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Remove"
				onPrimaryClicked={() => {
					if (checked) {
						localStorage.setItem(DISABLE_DELETE_CONTENT_TAG_MODAL, "true");
					}
					remove();
					setIsOpen(false);
				}}
				buttons="primary-check"
				layout="separated"
				variant="danger"
				isPrimaryDisabled={false}
				checkLabel="Don't show this again"
				isChecked={checked}
				onChecked={() => {
					setChecked(!checked);
				}}
			/>
		</Modal.Root>
	);
};
