import * as Popover from "@radix-ui/react-popover";
import { cx } from "@withjuly/frontend-common";
import { BaseInputProps, Label, ScrollArea } from "@withjuly/solisv2";
import { JSXElementConstructor, forwardRef, useState } from "react";

interface DropdownInputProps extends BaseInputProps {
	options: string[];
	onOptionSelected?: (value: string) => void;
	leadingIcon?: JSXElementConstructor<{ className?: string }>;
}

export const ContentCategoryInput = forwardRef<
	HTMLInputElement,
	DropdownInputProps
>((otherProps, ref) => {
	const {
		name,
		size = "md",
		error,
		errorMessage,
		label,
		description,
		rounded,
		placeholder,
		value,
		onChange,
		disabled,
		className,
		maxLength,
		autofocus,
	} = otherProps;

	const [showDropdown, setShowDropdown] = useState(() => false);

	return (
		<Popover.Root open={showDropdown} modal={false}>
			<div className="font-repro group flex w-full flex-col gap-2">
				{label ? (
					<Label size="xs" variant="overline" color="secondary">
						{label}
					</Label>
				) : null}
				<Popover.Trigger asChild={true}>
					<div
						className={cx(
							"focus-within:border-brand bg-slate-alpha-2 relative flex items-center justify-center gap-2 border-[2px] border-transparent",
							disabled && "opacity-40 hover:cursor-not-allowed",
							rounded && "rounded-full",
							!rounded && "rounded-solis-md",
							size === "md" && "h-10 pl-3 pr-2",
							size === "lg" && "h-12 pl-[18px] pr-[10px]",
							size === "xl" && "h-14 pl-[18px] pr-[10px]",
							error && "border-red-7 focus-within:border-red-7",
							className,
						)}
					>
						<input
							name={name}
							maxLength={maxLength}
							type="text"
							className="placeholder:text-text-placeholder text-paragraph-md hide-calendar h-5 w-full bg-transparent focus:outline-none focus:ring-0 disabled:cursor-not-allowed "
							placeholder={placeholder}
							ref={ref}
							value={value}
							onChange={(e) => {
								if (!showDropdown) {
									setShowDropdown(true);
								}

								onChange?.(e);
							}}
							disabled={disabled}
							autoFocus={autofocus}
							onBlur={() => {
								setTimeout(() => {
									setShowDropdown(() => false);
								}, 300);
							}}
						/>
					</div>
				</Popover.Trigger>
				{description ? (
					<p className="text-text-tertiary text-paragraph-xs">{description}</p>
				) : null}
				{error ? (
					<p className="text-red-7 text-paragraph-xs">{errorMessage}</p>
				) : (
					<div className="mt-4"></div>
				)}
			</div>

			<Popover.Portal className="z-[300] w-full">
				<Popover.Content
					onOpenAutoFocus={(e) => e.preventDefault()}
					onCloseAutoFocus={(e) => e.preventDefault()}
					onPointerDownOutside={() => {
						setShowDropdown(() => false);
					}}
					className="bg-surface-primary border-stroke-primary rounded-solis-md z-[200] flex w-[var(--radix-popover-trigger-width)] transform flex-col border data-[side=bottom]:translate-y-2 data-[side=top]:-translate-y-2"
				>
					<ScrollArea className="h-fit max-h-64 w-full overflow-y-scroll">
						{otherProps.options
							?.filter(
								(opt) =>
									value &&
									opt
										.replaceAll(" ", "")
										.toLowerCase()
										.includes(value?.replaceAll(" ", "").toLowerCase()),
							)
							.map((option, i) => {
								return (
									<div
										key={`${i}-${option}`}
										className="border-b-stroke-tertiary flex h-10 w-full items-center justify-between border-b-[0.5px] px-3 transition-colors last:border-0"
									>
										<p className="text-paragraph-md font-repro">{option}</p>
										<p className="text-paragraph-sm text-text-tertiary font-repro">
											Category already exists
										</p>
									</div>
								);
							})}
					</ScrollArea>
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
});

ContentCategoryInput.displayName = "ContentCategoryInput";

export const CONTENT_OPTIONS: string[] = [
	"Art",
	"Athlete",
	"Beauty",
	"Business",
	"Comedy",
	"Cooking",
	"DIY",
	"Education",
	"Entertainment",
	"Entrepreneurship",
	"Family",
	"Fashion",
	"Finance",
	"Fitness",
	"Food",
	"Gaming",
	"History",
	"Home",
	"Lifestyle",
	"Literature",
	"Mental Health",
	"Mobile Gaming",
	"Music",
	"Outdoors",
	"Parenthood",
	"Personal Finance",
	"Pets",
	"Photography",
	"Podcast",
	"Pop Culture",
	"Productivity",
	"Relationship",
	"Running",
	"Skincare",
	"Sports",
	"Tech",
	"Travel",
	"True Crime",
	"Wellness",
	"Yoga",
];
